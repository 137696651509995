<template>
  <td width="10%" class="game_right_wrap " :class="{'fixed': !sticky, 'stick': sticky}" valign="top" v-if="user" style="left: calc(73vw + 230px);">
    <div class="betting-slip">
      <div class="header">
          <!-- <img src="@/assets/img/ico/pin-o.png" v-if="!sticky" alt="" style="width: 24px; float: left;" @click="sticky = true;">
          <img src="@/assets/img/ico/pin.png" v-else alt="" style="width: 24px; float: left;" @click="sticky = false;"> -->
          <p class="tooltip" style="cursor:pointer; position:absolute; left:18px;" >
            <img src="@/assets/img/ico/pinoff.png"  @click="sticky = !sticky;" @mouseover="upHere = true" @mouseleave="upHere = false">
            <span class="tooltiptext2" style="display: block; z-index: 20000;"  v-if="upHere">
              클릭하시면 베팅슬립이 <font class="font_002">고정</font>되어<br> 스크롤에 따라 <font class="font_002">움직이지 않습니다.</font>
            </span>
          </p> 
           베팅슬립
      </div>
      <div class="content">
          <div class="toggle-container">
              <span style="margin-left: 104px; color:white;">배당변경 자동적용</span>
              <div class="toggle">
                  <input type="checkbox" id="auto-apply" v-model="autoModifyOdds" @click="autoModifyOdds = !autoModifyOdds">
                  <label for="auto-apply"></label>
                  <div class="delete" style="margin-left: 8px;" @click="$store.dispatch('ALL_CLEAR_ITEM');"><i class="fas fa-trash"></i></div>
              </div>
          </div>
          <div class="bet-item" v-for="(item, idx) in cartItem" :key="'MultiMcartItem'+idx">
              <div class="details">
                  <div class="info">
                      <div>
                        <p style="align-items: center; color: white;" >
                          <img :src="item.sportNameIcon" alt="" style="margin-right: 2px;">
                          {{ item.leagueNameKr || item.leagueNameEn }}
                        </p>
                      </div>
                      <p>{{ item.homeNameKr || item.homeNameEn }} vs {{ item.awayNameKr || item.awayNameEn }}</p>
                      <p>{{ item.marketNameKr || item.marketNameEn }}</p>
                      <p>{{ item | cartBetsName }} <span class="odds">{{ item.odds }}</span></p>
                  </div>
                  <div class="date-time">
                      <p>{{ item.startTime | formatDate('MM월 DD일')}}</p>
                      <p>{{ item.startTime | formatDate('HH:mm')}}</p>
                  </div>
              </div>
              <div class="delete" style="cursor: pointer;">
                  <i class="fas fa-trash" @click="deleteItemCart(item)"></i>
              </div>
          </div>
          <div class="freebet" :class="{'active': isActiveFreeBet}" v-if="user.freeBets.length > 0">
              <i class="fas fa-gift" ></i> 프리벳
              <div class="toggle">
                  <input type="checkbox" id="freebet-toggle" v-model="isActiveFreeBet" @click="isActiveFreeBet = !isActiveFreeBet">
                  <label for="freebet-toggle"></label>
              </div>
          </div>
          <div class="freebet-select" v-if="isActiveFreeBet" >
            <select name="" id="" class="freebet-amount" v-model="selectedFreeBet">
              <option :value="null">선택</option>
              <option v-for="bet in user.freeBets" :key="'freebet'+bet.seq" :value="bet">{{ bet.amount }} 원</option>
            </select>
          </div>
          <div class="summary">
              <p class="total-odds">총 배당률 <span>{{ odds }}</span></p>
              <div v-if="isActiveFreeBet"><span  class="betting-amount-name">베팅금액</span> <span class="bet-amount">{{ amount | makeComma }}</span></div>
              <p v-if="!isActiveFreeBet"><span class="betting-amount-name">베팅금액</span> <input type="number" class="bet-amount" v-model="amount"></p>
              <p><span class="winning-amount-name">예상당첨금액</span> <span class="expected-amount">{{ winnings | makeComma }}원</span></p>
          </div>
          <div class="amounts" v-if="!isActiveFreeBet" >
            <div class="amount" @click="addAmount(10000)">10,000</div>
            <div class="amount" @click="addAmount(100000)">100,000</div>
            <div class="amount" @click="addAmount(500000)">500,000</div>
            <div class="amount" @click="addAmount(1000000)">1,000,000</div>
          </div>
          <div class="buttons" v-if="!isActiveFreeBet" >
            <div class="button" @click="maxBet">최대배팅</div>
            <div class="button" @click="resetAmount">금액수정</div>
          </div>
          <button class="bet-button" @click="processBet">베팅하기</button>
      </div>
    </div>
  </td>
</template>

<script>
import MSG from '@/contants/msg'
// import Loading from '@/components/Loading'
import {mapState} from "vuex";
export default {
  name: "EuropeanCartNew",
  components: {
    // Loading
  },
  data: function(){
    return {
      sticky: false,
      processing: false,
      autoModifyOdds: true,
      isActiveFreeBet: false,
      selectedFreeBet: null,
      winnings: 0,
      amount: 0,
      maxOddsAmount: 0,
      limitWinnings: 0,
      maxAmount: 0,
      upHere: false,
    }
  },
  computed:{
    odds: function(){
      return Number(this.$store.getters["SPORT_TOTAL_ODDS"])
    },
    levelSetting: function (){
      return this.$store.getters["LEVEL_SETTING"]
    },
    minAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.minBetting);
    },
    maxWinAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.manyOther.maxWin);
    },
    maxNormalBetAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.maxBetting);
    },
    maxOneFolderAmount: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.soloOther.maxBetting);
    },
    maxOneFolderWinning: function(){
      return Number(this.levelSetting.prematchEuropeanSetting.soloOther.maxWin);
    },
    maxOdds: function(){
      return this.levelSetting.prematchEuropeanSetting.maxOdds;
    },
    oneFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.one;
    },
    twoFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.two;
    },
    threeFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.three;
    },
    fourFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.four;
    },
    fiveFolderDeduction: function(){
      return this.levelSetting.levelOption.deduction.five;
    },
    ...mapState({
      isView: state => state.modals.cart,
      user: state => state.user.user,
      cartItem: state => state.sports.cart,
    }),
  },
  watch:{
    // 배당변경시 자동으로 최대 가능 베팅금액 변경
    odds: function (val){
      if (Number(this.maxOdds) <= Number(val)) {
        this.cartItem.pop();
        return alert('최대 가능 배당을 초과했습니다.');
      }
      this.calWinnings();
    },
    // 베팅금액 초과시 자동으로 최대 가능 베팅금액 변경
    amount: {
      handler(val){
        if (this.maxAmount < Number(val)) {
          this.amount = this.maxAmount;
          return alert('베팅금액은 최대베팅금액을 초과할 수 없습니다.')
        }
        if (this.user.members_cash < Number(val)) {
          this.amount = this.user.members_cash;
          return alert('베팅금액은 보유금액을 초과할 수 없습니다.')
        }
        this.calWinnings();
      },
      immediate: true,
    },
    cartItem: {
      handler: function(val){
        if (val.length === 0) {
          this.maxAmount = Number(this.maxNormalBetAmount);
        }
        if (val.length === 1) {
          this.maxAmount = Number(this.maxOneFolderAmount);
          this.limitWinnings = this.maxOneFolderWinning;
        }
        if (val.length > 1) {
          this.maxAmount = Number(this.maxNormalBetAmount);
          this.limitWinnings = this.maxWinAmount;
        }
        this.calWinnings();
      },
      immediate: true,
      deep: true,
    },
    // 당첨금 변동
    winnings: function(val){
      if (Number(val) > this.limitWinnings){
        this.amount = Math.floor(this.limitWinnings / this.odds);
        return this.$store.state.dispatch('SHOW_ERR_MSG', `당첨금액은 ${this.$options.filters.makeComma(this.limitWinnings)}을 초과할 수 없습니다.`)
      }
    },
    isActiveFreeBet: function(val){
      if (!val) {
        this.selectedFreeBet = null;
      }
    },
    selectedFreeBet: function(val){
      if (val) {
        this.amount = val.amount;
      } else {
        this.amount = 0;
      }
    }
  },
  methods: {
    toggleCart: function(){
      return this.$store.dispatch('SHOW_MODAL', 'cart')
    },
    closeCart: function (){
      return this.$store.dispatch('INVISIBLE_ALL_MODALS')
    },
    deleteItemCart: function(obj){
			if(this.cartItem.length === 1) this.closeCart();
      return this.$store.dispatch('DELETE_CART_ITEM', obj)
    },
    maxBet: function(){
      const values = [Math.floor(this.limitWinnings/this.odds), this.maxAmount, this.user.members_cash]
      this.amount = Math.min.apply(null, values)
      // if (this.winnings < this.limitWinnings){
      //   this.amount = Math.floor(this.limitWinnings / this.odds);
      // }
    },
    processBet: function(){
      let data = {};
      if (this.amount < this.minAmount) return alert(`최소 베팅금은 ${this.$options.filters.makeComma(this.minAmount)}원 입니다.`);
      if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.one) < 0){
        let confirmMsg = `단폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.one}% 배당 하락이 발생합니다.`;
        const oneConfirm = confirm(confirmMsg);
        if (!oneConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.one, '유저설정-단폴베팅')
      } else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 1 && this.oneFolderDeduction) {
          let confirmMsg = `단폴 베팅시 ${this.oneFolderDeduction}% 배당 하락이 발생합니다.`;
          const singleConfirm = confirm(confirmMsg);
          if (!singleConfirm) {
            return;
          }
          this.singleBetDeduction()
        }
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.two) < 0
      ){
        let confirmMsg = `투폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.two}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.two, '유저설정-투폴베팅')
      }
      else {
        if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 2 && this.twoFolderDeduction){
          let confirmMsg = `투폴 베팅시 ${this.twoFolderDeduction}% 배당 하락이 발생합니다.`;
          const doubleConfirm = confirm(confirmMsg);
          if (!doubleConfirm) {
            return;
          }
          this.doubleBetDeduction()
        }
      }

      if (this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length === 3 && ['', 0, '0'].indexOf(this.user.members_detail_setting.베팅차감.스포츠.three) < 0){
        let confirmMsg = `세폴 베팅시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) {
          return;
        }
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴베팅')
      }

      if (
          this.cartItem.filter(e=>e.leagueNameEn !== '보너스').length > 3 &&
          this.user.is_discount_three_over === 'Y'
      ){
        let confirmMsg = `세폴 베팅 초과시 ${this.user.members_detail_setting.베팅차감.스포츠.three}% 배당 하락이 발생합니다.`;
        const doubleConfirm = confirm(confirmMsg);
        if (!doubleConfirm) return;
        this.betDeduction(this.user.members_detail_setting.베팅차감.스포츠.three, '유저설정-세폴초과베팅')
      }
      

      data.bettings = this.cartItem;
      data.amount = this.amount;
      data.totalOdds = this.odds;
      data.winnings = this.winnings;
      data.process_freebet = this.isActiveFreeBet; 
      data.selected_freebet = this.selectedFreeBet; 
      if (this.isActiveFreeBet && !this.selectedFreeBet) {
        return alert('프리벳을 선택후 진행해주세요');
      }
      this.$store.dispatch('SET_PROCESS', data);

      if (this.cartItem.find(b => b.betsStatus === 'y')){
        this.$store.dispatch('SHOW_ERR_MSG', MSG.notAvailableBet);
        this.$store.dispatch('SHOW_MODAL', 'bettingStatus');
        return;
      }
      // if (this.odds > this.maxOdds) return this.$store.dispatch('SHOW_ERR_MSG', '최대 배당을 초과하여 베팅할 수 없습니다.')
      if (this.odds > this.maxOdds) {
        this.$store.dispatch('SHOW_ERR_MSG', '최대 배당을 초과하여 베팅할 수 없습니다.');
        return
      }
      if (!this.isUpdate && this.cartItem.find(e => String(e.betsPrevOdds) !== String(e.odds) && e.betType !== '보너스')){
        this.$store.dispatch('SHOW_MODAL', 'bettingUpdate')
        return;
      }

      if (this.isUpdate && this.cartItem.find(e => String(e.betsPrevOdds) !== String(e.odds) && e.betType !== '보너스')){
        this.$store.dispatch('SHOW_MODAL', 'bettingUpdateProcess')
        return;
      }

      this.$store.dispatch('SHOW_MODAL', 'bettingProcessEuropeanMulti')
      //다폴더

      this.amount = 0;
      this.selected_freebet = null; 
      this.$store.dispatch('ALL_CLEAR_ITEM');
    },
    calWinnings: function (){
      this.winnings = Math.floor(this.amount * this.odds);
    },
    betDeduction:function(penalty, reason){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = penalty;
        this.cartItem[c].penaltyReason = reason;
      }
    },
    singleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.oneFolderDeduction;
        this.cartItem[c].penaltyReason = '단폴 베팅';
      }
    },
    doubleBetDeduction:function(){
      for (const c in this.cartItem){
        this.cartItem[c].penalty = this.twoFolderDeduction;
        this.cartItem[c].penaltyReason = '투폴 베팅';
      }
    },
    addAmount(amt){
      this.amount += amt;
    },
    resetAmount(){
      this.amount = 0;
    },
  }
}
</script>

<style scoped>
.fixed {
  position: fixed;
}

.stick {
  position: absolute;
}

.betting-slip {
    width: 300px;
    background-color: #1c1c1c;
    /* border-radius: 10px; */
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.header {
    background-color: #da2c36;
    padding: 20px;
    text-align: center;
    font-size: 24px;
}
/* .header i {
    margin-right: 10px;
} */
.content {
    flex: 1;
    /* overflow-y: auto; */
}
.toggle-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #262a2b;
    padding: 10px;
    border-bottom: 1px solid #000;
    /* border-radius: 5px; */
}
.toggle-container span {
    font-size: 14px;
}
.toggle-container .toggle {
    display: flex;
    align-items: center;
}
.toggle-container .toggle input {
    display: none;
}
.toggle-container .toggle label {
    background-color: #555;
    border-radius: 15px;
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 40px;
}
.toggle-container .toggle label::after {
    background-color: #ccc;
    border-radius: 50%;
    content: '';
    height: 16px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: 0.3s;
    width: 16px;
}
.toggle-container .toggle input:checked + label {
    background-color: #da2c36;
}
.toggle-container .toggle input:checked + label::after {
    left: 22px;
}
.bet-item {
    background-color: #262a2b;
    /* border-radius: 5px; */
    padding: 10px;
    position: relative;
    /* border-top: 1px solid #444; */
    border-bottom: 1px solid #444;
}
.bet-item .icon {
    font-size: 24px;
    margin-right: 10px;
}
.bet-item .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bet-item .details .info {
    flex: 1;
}
.bet-item .details .info p {
    margin: 5px 0;
    color: white;
}
.bet-item .details .info .odds {
    background-color: #da2c36;
    border-radius: 5px;
    color: white;
    display: inline-block;
    padding: 2px 5px;
}
.bet-item .details .date-time {
    text-align: right;
    color: white;
}
.bet-item .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.freebet {
    background-color: #000;
    /* border-radius: 5px; */
    color: white;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    padding: 10px;
}
.active {
  background-color: #da2c36 !important;
  color: black !important;
}

.freebet i {
    margin-right: 10px;
    color:#da2c36;
}
.active i {
  color: #000 !important;
}

.freebet .toggle {
    margin-left: 182px;
    display: flex;
    align-items: center;
}
.freebet .toggle input {
    display: none;
}
.freebet .toggle label {
    background-color: #555;
    border-radius: 15px;
    cursor: pointer;
    height: 20px;
    position: relative;
    width: 40px;
}
.freebet .toggle label::after {
    background-color: #ccc;
    border-radius: 50%;
    content: '';
    height: 16px;
    left: 2px;
    position: absolute;
    top: 2px;
    transition: 0.3s;
    width: 16px;
}
/* .freebet .toggle input:checked + label {
    background-color: #da2c36;
} */
.freebet .toggle input:checked + label::after {
    left: 22px;
}
.freebet-select {
    /* background-color: #da2c36; */
    /* border-radius: 5px; */
    /* color: black; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #262a2b;
    padding: 10px;
}
.freebet-amount {
    background-color: #262a2b;
    /* border: none; */
    border-radius: 3px;
    color: white;
    /* padding: 10px; */
    width: 100%;
    /* text-align: center; */
    /* margin: 10px; */
    height: 30px;
}
.league-name{
  margin-top: -2px;
  color: white;
}
.summary {
    background-color: #262a2b;
    /* border-radius: 5px; */
    padding: 10px;
    /* margin: 10px; */
}
.summary p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}
.summary .total-odds {
    font-size: 12px;
    color: white;
}
.summary .bet-amount {
    background-color: black;
    border-radius: 5px;
    padding: 5px;
    text-align: right;
    color: white;
    height: 12px;
}
.betting-amount-name {
  margin-top: 6px;
  color: white;
}
.winning-amount-name {
  margin-top: 7px;
  color: white;
}
.summary .expected-amount {
    /* background-color: black; */
    /* border-radius: 5px; */
    padding: 5px;
    text-align: right;
    color: white;
}
.bet-button {
    background-color: #da2c36;
    border: none;
    /* border-radius: 5px; */
    color: white;
    font-size: 18px;
    padding: 15px;
    text-align: center;
    width: 100%;
    /* margin: 10px; */
    cursor: pointer;
}
.amounts {
    display: flex;
    /* justify-content: space-between; */
    width: 100%;
    /* margin-bottom: 10px; */
    border-top: 1px solid #444;
    padding: 10px;
    background-color: #262a2b;
    gap: 3px;
}
.amounts .amount {
    background-color: #5b060b;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 16%;
    color: white;
    text-align: center;
    font-size: 9px;
}

.buttons {
    display: flex;
    /* justify-content: space-between; */
    gap: 10px;
    width: 100%;
    padding: 0 10px 10px 10px;
    /* margin-bottom: 10px; */
    background-color: #262a2b;
}

.buttons .button {
    border: 2px solid #da2c36;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 37%;
    text-align: center;
    background-color: transparent;
    color: #ffffff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

</style>
