import axios from 'axios';
import store from '../index';

const API_URL = process.env.VUE_APP_API_URL;
const getDefaultState = () => {
  return {
    originList: [],
    dateCnt: [],
    cart: [],
  }
}

const state = getDefaultState()

const getters = {
  //카트 안에 담긴 배당내역의 총합
  SPORT_LSPORT_TOTAL_ODDS: function(state) {
    try {
      return Math.floor(state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1) * 100) / 100;
    } catch (error) {
      // 에러 발생 대비로 캐치블럭에 변경정 로직
      return state.cart.reduce((prev, curr) => prev * Number(curr.odds), 1).toFixed(2);
    }
  },
}

const actions = {
  GET_LSPORT_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/lsport/korean/prematch`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LSPORT_LIST', response.data?.payload.list.filter(g => g.status === '대기'))
        }
        return response
      })
  },
  GET_LSPORT_SPECIAL_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/lsport/korean/special`, params)
      .then((response) => {
        if (response.data){
          console.log('스토어 GET_LSPORT_SPECIAL_LIST', response.data)
          commit('SET_LSPORT_LIST', response.data?.payload.list.filter(g => g.status === '대기'))
        }
        return response
      })
  },
  GET_LSPORT_EUROPEAN_LIST({commit}, params) {
    return axios.post(`${API_URL}/v2/lsport/european/list`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LSPORT_LIST', response.data?.payload.list.filter(g => g.status === '대기'))
        }
        return response
      })
  },
  GET_LSPORT_DATE_CNT({commit}, params) {
    return axios.post(`${API_URL}/v2/lsport/european/dateCnt`, params)
      .then((response) => {
        if (response.data){
          commit('SET_LSPORT_DATE', response.data?.payload)
        }
        return response
      })
  },
  GET_LSPORT_DETAIL(_, params) {
    return axios.post(`${API_URL}/v2/lsport/european/detail`, params)
      .then((response) => {
        return response
      })
  },
  ADD_LSPORT_CART_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      commit('POP_ITEM_LSPORT_CART_BY_FOLDER', obj);
      handleBonusItem(state, commit)
      return; // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.SPORT_LSPORT_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const cartItem = state.cart.find(item => item.betsSeq === obj.betsSeq);
    if (!cartItem) {
      // 추가할 item 이 카트의 게임과 일치하지 않을 경우, 카트에 새로 추가
      // 동일경기는 기본적인 동일경기 설정
      const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
      // 조합 가능 구분 여부
      const betOption = store.state.setting.settings.sportConfig.combinationMix2.filter(e=> e.status === '사용');

      // const betOption =  state.bettingOptions.filter(e => e.list === true)
      // console.log(obj);
      const sportsBetOption = betOption.filter(e => Number(e.sport) === obj.sportsId)
      // console.log(sportsBetOption);
      if ( dupItem.length >= 2 ) {
        return alert('동일경기에서 3가지 이상 조합은 불가능합니다.')
      }

      if (dupItem.length > 0) {
        // 종목별 조합 검증항목 배열에 담음
        const cartItemInMarketId = dupItem.map(e => e.marketsId);
        cartItemInMarketId.push(obj.marketsId);
        const verifyResult = [];
        // console.log(sportsBetOption)
        const alloweds = sportsBetOption.map(e => e.allowed);
        for (const i in alloweds){
          let verify = alloweds[i].filter(e => e !== null);
          let inResult = [];
          for (const j in verify){
            let combination = verify[j].id;
            // console.log(cartItemInMarketId);
            // console.log(cartItemInMarketId.indexOf(String(combination)), combination);
            inResult.push(cartItemInMarketId.indexOf(String(combination)));
          }
          verifyResult.push(inResult);
        }

        // 배열에 담긴 조합 검증항목을 체크
        let res = false;
        for (const v in verifyResult) {
          const verify = verifyResult[v];
          // console.log(verify);
          if (verify.indexOf(-1) < 0) res = true;
        }
        if (!res){
          store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
          store.state.modals.deduction = true;
          return;
        }

        // 축구일때 승무패 배당차감
        const soccerOption = store.state.setting.settings.sportConfig.common.soccerCombination;
        if (obj.sportsId === 210 && soccerOption.active) {
          const SMP = state.cart.find(e => e.marketNameKr.indexOf('승무패') >= 0 && e.gameId === obj.gameId);
          if ((SMP && SMP.betsName === 'X') || obj.betsName === 'X') {
            store.state.modals.errorMsg = '해당 조합은 베팅이 불가합니다.';
            store.state.modals.deduction = true;
            return
          }

          store.state.modals.errorMsg = '축구 동일경기 조합베팅시 차감 배당값으로 적용됩니다.';
          store.state.modals.deduction = true;

          // 이미 승무패가 등록되어있을시
          if (SMP){
            if (Number(SMP.odds) >= 1.01 && Number(SMP.odds) < 1.50) {
              SMP.betsPrevOdds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
              SMP.odds = Number(SMP.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(SMP.odds) - Number(soccerOption.division1)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
            } else if (Number(SMP.odds) >= 1.51 && Number(SMP.odds) < 1.99) {
              SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
              SMP.odds = (Number(SMP.odds) - Number(soccerOption.division2)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
            } else {
              SMP.betsPrevOdds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
              SMP.odds = (Number(SMP.odds) - Number(soccerOption.division3)).toFixed(2);
              SMP.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
            }
          }
          // 승무패가 후에 추가될시
          if (obj.marketNameKr.indexOf('승무패') >= 0) {
            if (Number(obj.odds) >= 1.01 && Number(obj.odds) < 1.50) {
              obj.betsPrevOdds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
              obj.odds = Number(obj.odds) - Number(soccerOption.division1) < 1 ? 1 : (Number(obj.odds) - Number(soccerOption.division1)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division1}`
            } else if (Number(obj.odds) >= 1.51 && Number(obj.odds) < 1.99) {
              obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
              obj.odds = (Number(obj.odds) - Number(soccerOption.division2)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division2}`
            } else {
              obj.betsPrevOdds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
              obj.odds = (Number(obj.odds) - Number(soccerOption.division3)).toFixed(2);
              obj.penaltyReason = `축구 동일경기 배당차감 ${soccerOption.division3}`
            }
          }
        }
      }
      commit('PUSH_LSPORT_ITEM_CART', obj);
      handleBonusItem(state, commit)
    } else {
      commit('POP_LSPORT_ITEM', obj); // 있을시 삭제
    }
  },
  ADD_LSPORT_SPECIAL_CART_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      commit('POP_ITEM_LSPORT_CART_BY_FOLDER', obj);
      handleBonusItem(state, commit)
      return; // 있을시 삭제
    }
    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (Number(getters.SPORT_LSPORT_TOTAL_ODDS) * Number(obj.odds) >  Number(checkMaxOdds)) return alert('최대배당을 초과하셨습니다.')

    const cartItem = state.cart.find(item => item.betsSeq === obj.betsSeq);
    if (!cartItem) {
      // 추가할 item 이 카트의 게임과 일치하지 않을 경우, 카트에 새로 추가
      const dupItem = state.cart.filter(item => item.gameId === obj.gameId);

      // console.log(sportsBetOption);
      if ( dupItem.length >= 2 ) {
        return alert('동일경기에서 3가지 이상 조합은 불가능합니다.')
      }

      if (dupItem.length > 0) {
        // 종목별 조합 검증항목 배열에 담음
        // 농구아니면 조합 불가
        if(obj.sportsId === 48242) {
          const combinableMarkets = [21, 64]
          if(combinableMarkets.includes(Number(obj.marketsId))){
            const marketIds = dupItem.map(item => item.marketsId)
            if(marketIds.includes(obj.marketsId)) {
              store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
              store.state.modals.deduction = true;
              return;
            }
          } else {
            store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
            store.state.modals.deduction = true;
            return;
          }
        } else {
          store.state.modals.errorMsg = '동일경기 불가능한 조합입니다.';
          store.state.modals.deduction = true;
          return;
        }
      }
      commit('PUSH_LSPORT_ITEM_CART', obj);
      handleBonusItem(state, commit)
    } else {
      commit('POP_LSPORT_ITEM', obj); // 있을시 삭제
    }
  },

  ADD_LSPORT_EUROPEAN_CART_ITEM({state, commit, getters}, obj){
    const checkFolderCount = store.getters['LEVEL_SETTING'].prematchSetting.maxFolders;

    const cartInItemCnt = state.cart.filter(e => e.betType !== '보너스').length;
    if (checkFolderCount <= cartInItemCnt) return alert(`최대가능 폴더수는 ${checkFolderCount}개 입니다.`)
    // 카트에 동일경기 아이템이 추가되었을때
    const equalCart = state.cart.find(item => item.foldersSeq === obj.foldersSeq);
    if (equalCart){
      commit('POP_ITEM_LSPORT_CART_BY_FOLDER', obj);
      // handleBonusItem(state, commit)
      return; // 있을시 삭제
    }

    const checkMaxOdds = store.getters['LEVEL_SETTING'].prematchSetting.maxOdds;
    if (
      Number(getters.SPORT_LSPORT_TOTAL_ODDS) * Number(obj.odds) >
      Number(checkMaxOdds)
    )
      return alert("최대배당을 초과하셨습니다.");

    const dupItem = state.cart.filter(item => item.gameId === obj.gameId);
    if ( dupItem.length > 0 ) {
      return alert('동일경기에서 조합은 불가능합니다.')
    }

    commit('PUSH_LSPORT_ITEM_CART', obj);
    // handleBonusItem(state, commit)
  },
  DELETE_LSPORT_CART_ITEM({ commit, state }, obj){
    commit('POP_LSPORT_ITEM_CART', obj); // 있을시 삭제
    handleBonusItem(state, commit)
  },
  DELETE_LSPORT_CART_ITEM_GAME({commit}, gameId){
    commit('POP_LSPORT_ITEM_BY_GAME', gameId); // 있을시 삭제
  },
  ALL_LSPORT_CLEAR_ITEM({ commit }){
    return commit('RESET_LSPORT_SPORTS_CART')
  },
  CLEAR_LSPORT_CART({commit}){
    return commit('RESET_LSPORT_CART')
  },
  UPDATE_KOREAN({commit}, data) {
    commit('SET_KOREAN_PRE_UPDATE', data)
  },
  UPDATE_LSPORT_DATA({commit}, data){
    commit('SET_LSPORT_DETAIL_DATA', data)
  },
}

const mutations = {
  SET_LSPORT_LIST(state, payload) {
    state.originList = payload
  },
  SET_LSPORT_DATE(state, payload) {
    state.dateCnt = payload
  },
  PUSH_LSPORT_ITEM_CART(state, obj){
    state.cart.push(obj)
  },
  POP_LSPORT_ITEM_INDEX(state, index){
    return state.cart.splice(index, 1);
  },
  POP_ITEM_LSPORT_CART_BY_FOLDER(state, obj){
    const index = state.cart.findIndex(i => i.foldersSeq === obj.foldersSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_LSPORT_ITEM_CART(state, obj){
    const index = state.cart.findIndex(i => i.betsSeq === obj.betsSeq);
    if (index === -1) return state.cart = [...state.cart, obj];
    return state.cart.splice(index, 1);
  },
  POP_LSPORT_ITEM_BY_GAME(state, gameId){
    const index = state.cart.findIndex(i => i.gameId === gameId);
    if (index === -1) {
      state.cart = [...state.cart ]
      return;
    }
    return state.cart.splice(index, 1);
  },
  RESET_LSPORT_CART(state){
    state.cart = [];
  },
  RESET_LSPORT_SPORTS_CART(state){
    state.cart = [];
  },
  SET_KOREAN_PRE_UPDATE: function (state, data) {
    // console.log(data)
    const config = store.state.setting.settings.sportConfig.common;
    for (const i in data) {
      const gameData = data[i];
      // console.log(gameData);
      const game = state.originList.find(e => String(e.id) === String(gameData.MatchId))
      if (game) {
        // 폴더들
        const folder = game.folders.find(e => String(e.id) === String(gameData.Id))
        if (folder) {
          folder.isVisible = gameData.IsVisible ? 'y' : 'n';
          folder.isSuspended = gameData.IsSuspended ? 'y' : 'n';
          if (gameData.Selections.length === 2){
            // console.log('folderUpdate', gameData, game.homeTeam.nameKr || game.homeTeam.nameEn);
            const oddsSum = gameData.Selections.reduce((prev, curr) => prev + curr.Price, 0);
            if (oddsSum > Number(config.sum.odds)){
              const odds1 =  gameData.Selections[0];
              const odds2 =  gameData.Selections[1];
              if (Number(odds1.Price) > Number(odds2.Price)){
                const baseBet = folder.bets.find(e => String(e.id) === String(odds2.Id))
                if (baseBet) {
                  baseBet.price = Number(odds2.Price).toFixed(2);
                  const baseCartItem = store.state.cart.sports.find(e => String(e.betsId) === String(odds2.Id));

                  if (baseCartItem) {
                    baseCartItem.odds =  Number(odds2.Price).toFixed(2);
                    baseCartItem.betsStatus = odds1.IsSuspended ? 'y' : 'n';
                    baseCartItem.betsUpdated = true;
                  }
                }
                const deductionBet = folder.bets.find(e => String(e.id) === String(odds1.Id))
                if (deductionBet){
                  deductionBet.price = (Number(config.sum.odds) - Number(odds2.Price)).toFixed(2);
                  const deductionCartItem = store.state.cart.sports.find(e => String(e.betsId) === String(odds1.Id));

                  if (deductionCartItem) {
                    deductionCartItem.odds = (Number(config.sum.odds) - Number(odds2.Price)).toFixed(2);
                    deductionCartItem.betsStatus = odds1.IsSuspended ? 'y' : 'n';
                    deductionCartItem.betsUpdated = true;
                  }
                }
              }
              if (Number(odds1.Price) < Number(odds2.Price)){
                const baseBet = folder.bets.find(e => String(e.id) === String(odds1.Id))
                if (baseBet) {
                  baseBet.price = Number(odds1.Price).toFixed(2);
                  const baseCartItem = store.state.cart.sports.find(e => String(e.betsId) === String(odds1.Id));

                  if (baseCartItem) {
                    baseCartItem.odds =  Number(odds1.Price).toFixed(2);
                    baseCartItem.betsStatus = odds1.IsSuspended ? 'y' : 'n';
                    baseCartItem.betsUpdated = true;
                  }
                }

                const deductionBet = folder.bets.find(e => String(e.id) === String(odds2.Id))
                if (deductionBet){
                  deductionBet.price = (Number(config.sum.odds) - Number(odds1.Price)).toFixed(2);
                  const deductionCartItem = store.state.cart.sports.find(e => String(e.betsId) === String(odds2.Id));

                  if (deductionCartItem) {
                    deductionCartItem.odds = (Number(config.sum.odds) - Number(odds1.Price)).toFixed(2);
                    deductionCartItem.betsStatus = odds1.IsSuspended ? 'y' : 'n';
                    deductionCartItem.betsUpdated = true;
                  }
                }
              }

            } else {
              for (const b in gameData.Selections) {
                const socketBet = gameData.Selections[b];
                const cartItem = store.state.cart.sports.find(e => e.betsId === socketBet.Id);
                const bet = folder.bets.find(e => String(e.id) === String(socketBet.Id))
                if (bet) {
                  bet.price = Number(socketBet.Price).toFixed(2);

                  if (cartItem) {
                    cartItem.odds = bet.price;
                    cartItem.betsStatus = socketBet.IsSuspended ? 'y' : 'n';
                    cartItem.betsUpdated = true;
                  }
                }
              }
            }
          } else if (gameData.Selections.length === 3) {
            for (const b in gameData.Selections) {
              const socketBet = gameData.Selections[b];
              const cartItem = store.state.cart.sports.find(e => e.betsId === socketBet.Id);
              const bet = folder.bets.find(e => String(e.id) === String(socketBet.Id))
              if (bet) {
                if (folder.market.nameKr.indexOf('승무패') >= 0) {
                  if (bet.name === 'W1' || bet.name === 'W2') {
                    if (Number(socketBet.Price) > Number(config.maxOdds.reverse)) {
                      bet.price = Number(config.maxOdds.reverse);
                    }
                  } else {
                    if (Number(socketBet.Price) > Number(config.maxOdds.draw)) {
                      bet.price = Number(config.maxOdds.draw);
                    } else {
                      bet.price = Number(socketBet.Price).toFixed(2);

                    }
                  }
                } else {
                  bet.price = Number(socketBet.Price).toFixed(2);
                }

                if (cartItem) {
                  cartItem.odds = bet.price;
                  cartItem.betsStatus = socketBet.IsSuspended;
                  cartItem.betsUpdated = true;
                }
              }
            }

          }
          else {
            for (const b in gameData.Selections) {
              const socketBet = gameData.Selections[b];
              const cartItem = store.state.cart.sports.find(e => e.betsId === socketBet.Id);
              const bet = folder.bets.find(e => String(e.id) === String(socketBet.Id))
              if (bet) {
                bet.price = Number(socketBet.Price).toFixed(2);
                if (cartItem) {
                  cartItem.odds = bet.price;
                  cartItem.betsStatus = socketBet.IsSuspended;
                  cartItem.betsUpdated = true;
                }
              }
            }
          }
        }
      }
    }
    // const game = state.originList.find(e => e.id === data.gameId)
    // console.log(game);
    // const config = store.state.setting.settings.sportConfig.common;
    // // 경기목록 업데이트
    // if (game) {
    //     // 폴더들
    //     if (data.odds[0].bets_line) {
    //         const lines = data.odds.map(e => e.bets_line);
    //         const uniLines = Array.from(new Set(lines));
    //         for (const l in uniLines) {
    //             // 기준점이 맞는 데이터 구조에 맞춰 설정
    //             const odds = data.odds.filter(e => e.bets_line === uniLines[l]);
    //             // 북메이커에 맞는 데이터 수신 폴더 검색
    //             const folder = game.folders.find(e => e.market.seq === data.marketId && e.folders_line === uniLines[l] && e.folders_bookmaker === data.providerId);
    //             if (folder) {
    //                 for (const o in odds) {
    //                     const odd = odds[o];
    //                     const bets = folder.bets.find(e => String(e.id) === String(odd.id) && e.name === odd.name)
    //                     if (bets) {
    //                         bets.bets_price = Number(odd.bets_price).toFixed(2)
    //                         bets.bets_status = odd.bets_status
    //                     }
    //                     const cartItem = store.state.cart.sports.find(e => e.betsId === odd.id && e.betsName === odd.name);
    //                     if (cartItem){
    //                         cartItem.odds = Number(odd.bets_price).toFixed(2)
    //                         cartItem.betsStatus = odd.bets_status
    //                         cartItem.betsUpdated = true;
    //                     }
    //                 }
    //             }
    //         }
    //     } else {
    //         const folder = game.folders.find(e => e.market.seq === data.marketId && e.folders_bookmaker === data.providerId);
    //         if (folder) {
    //             for (const o in data.odds) {
    //                 const odd = data.odds[o];
    //                 const bets = folder.bets.find(e => String(e.id) === String(odd.id) && e.name === odd.name)
    //                 // 승무패 일때
    //                 if (folder.bets.length > 2) {
    //                     if (bets) {
    //                         const cartItem = store.state.cart.sports.find(e => e.betsId === odd.id && e.betsName === odd.name);
    //
    //                         if (bets.name === 'X'){
    //                             if (Number(odd.bets_price) > Number(config.maxOdds.draw)){
    //                                 bets.bets_price = Number(config.maxOdds.draw)
    //                                 bets.bets_status = odd.bets_status
    //                                 if (cartItem){
    //                                     cartItem.odds = Number(config.maxOdds.draw)
    //                                     cartItem.betsStatus = odd.bets_status
    //                                     cartItem.betsUpdated = true;
    //                                 }
    //                             } else {
    //                                 bets.bets_price = Number(odd.bets_price).toFixed(2)
    //                                 bets.bets_status = odd.bets_status
    //                                 if (cartItem){
    //                                     cartItem.odds = Number(odd.bets_price).toFixed(2)
    //                                     cartItem.betsStatus = odd.bets_status
    //                                     cartItem.betsUpdated = true;
    //                                 }
    //                             }
    //
    //
    //                         } else {
    //                             if (Number(odd.bets_price) >  Number(config.maxOdds.reverse)){
    //                                 bets.bets_price = Number(config.maxOdds.reverse)
    //                                 bets.bets_status = odd.bets_status
    //                                 if (cartItem){
    //                                     cartItem.odds = Number(config.maxOdds.reverse)
    //                                     cartItem.betsStatus = odd.bets_status
    //                                     cartItem.betsUpdated = true;
    //                                 }
    //                             } else {
    //                                 bets.bets_price = Number(odd.bets_price).toFixed(2)
    //                                 bets.bets_status = odd.bets_status
    //                                 if (cartItem){
    //                                     cartItem.odds = Number(odd.bets_price).toFixed(2)
    //                                     cartItem.betsStatus = odd.bets_status
    //                                     cartItem.betsUpdated = true;
    //                                 }
    //                             }
    //                         }
    //
    //                     }
    //                 } else {
    //                     if (bets) {
    //                         bets.bets_price = Number(odd.bets_price).toFixed(2)
    //                         bets.bets_status = odd.bets_status
    //                         const cartItem = store.state.cart.sports.find(e => e.betsId === odd.id && e.betsName === odd.name);
    //                         if (cartItem){
    //                             cartItem.odds = Number(odd.bets_price).toFixed(2)
    //                             cartItem.betsStatus = odd.bets_status
    //                             cartItem.betsUpdated = true;
    //                         }
    //                     }
    //                 }
    //
    //             }
    //         }
    //     }
    // }
  },
  // 엘스포츠 디테일 데이터 변경
  SET_LSPORT_DETAIL_DATA(state, data) {
    // console.log(data.data)
    const result = data.data;
    const game = state.originList.find(game => game.id === result.game.id)
    if (game){
      const gameFolder = game.folders[0];
      if(gameFolder){
        const folder = result.list.find(folder => String(folder.id) === String(gameFolder.id));
        gameFolder.bets.map((bet) => {
          folder.bets.map(recentBet => {
            if (recentBet.id === bet.id) bet.price = recentBet.price;
          })
        })
      }
    }
  }
}

function handleBonusItem(state, commit) {
  const cartItem = state.cart.filter(e => e.betType !== '보너스');
    // 보너스 최소 배당 값 가져오기
    const minOddsOption = store.state.setting.settings.config.option.siteOption2.find(e => e.name === '보너스최소배당');
    const minOdds = minOddsOption ? Number(minOddsOption.value) : 1.3; // 기본값 설정
  
    // 최소 배당 조건을 만족하지 않는 항목이 있는지 확인
    const hasBelowMinOdds = cartItem.some(item => Number(item.odds) < minOdds);
  // 장바구니 아이템 수에 따른 보너스 배당률 설정
  let bonusRate = 1;
  let bonusName = null;
  if (cartItem.length >= 3 && cartItem.length < 5) {
      bonusRate = 1.03;
      bonusName = 3;
  } else if (cartItem.length >= 5 && cartItem.length < 7) {
      bonusRate = 1.05;
      bonusName = 5;
  } else if (cartItem.length >= 7) {
      bonusRate = 1.07;
      bonusName = 7;
  }
  console.log(bonusRate, bonusRate !== undefined, 'delete');
  // 보너스 아이템 정의
  const bonusItem = bonusRate > 1 ? {
      gameId: null,
      betsSeq: null,
      betsName: '1',
      sportsId: null,
      marketsId: null,
      foldersSeq: null,
      odds: bonusRate,
      betsUpdated: false,
      betsPrevOdds: bonusRate,
      line: null,
      leagueNameKr: '보너스',
      leagueNameEn: '보너스',
      leagueNameIcon: null,
      sportNameKr: '보너스',
      sportNameEn: '보너스',
      sportNameIcon: null,
      homeNameKr: `${bonusName}폴 보너스`,
      homeNameEn: `${bonusName}폴 보너스`,
      awayNameKr: '보너스',
      awayNameEn: '보너스',
      marketNameKr: '보너스',
      marketNameEn: '보너스',
      startTime: null,
      odds1: bonusRate,
      odds2: bonusRate,
      odds3: bonusRate,
      betType: '보너스',
      detailType: null,
      currentPeriod: 0,
      currentTime: 0,
      homeScore: 0,
      awayScore: 0,
      penalty: null,
      penaltyReason: null,
  } : null;

  // 장바구니에 보너스가 이미 있는지 확인
  const bonusIndex = state.cart.findIndex(cartItem => cartItem.betType === '보너스');
  console.log(bonusRate, 'pop');
  // 보너스 추가 또는 제거
  if (bonusRate > 1 && !hasBelowMinOdds) { // 최소 배당 조건 추가
    if (bonusIndex !== -1) {
      // 기존 보너스 제거
      state.cart.splice(bonusIndex, 1);
    } 
    if (bonusItem) commit('PUSH_LSPORT_ITEM_CART', bonusItem);
  } else if (bonusIndex !== -1) {
      // 보너스가 필요 없을 때 제거
      state.cart.splice(bonusIndex, 1);
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
